import React, { useEffect, useState } from 'react';
import {withTranslation} from "react-i18next";

import ProductContactTitle from "./component.product-contact-title";
import ContactFormFields from "./component.product-contact-fields";
import useFetchPost from "../../hooks/hook.useFetchPost";
import Button from '../component.button';

const ContactForm = ({ productName, classInfo, brandName, brandLogo, boatClass, selectedBoatClass, setBoatClassValues, selectedHullSize, setSelectedHullSize, productType, t }) => {
    let availableHullShapes = productType === 'boat' ? classInfo[boatClass].availableHullShapes : [];
    if(availableHullShapes === null) {
        availableHullShapes = [];
    }
    const [formSubmitted, setFormSubmitted] = useState(false);
    const [selectedCox, setSelectedCox] = useState('');
    const defaultContactDetails = {
        productName: productName,
        country: '',
        phoneNumber: '',
        contactName: '',
        club: '',
        email: '',
        weight: '',
        message: '',
        boatClass: '',
        hullSize: '',
        cox: ''
    }




    const [contactDetails, setContactDetails] = useState(defaultContactDetails);

    const formSubmission = useFetchPost({
        path: '/wp-json/contact-form-7/v1/contact-forms/330/feedback',
        data: contactDetails,
        submitClick: formSubmitted
    });

    const handleInputChange = event => {
        const target = event.target;
        const value = target.value;
        const name = target.name;

        setContactDetails({
            ...contactDetails,
            [name]: value,
        });
    };

    const handleSubmit = async event => {
        event.preventDefault();

        let formCox = selectedCox;

        if(selectedBoatClass !== "Four/Quad") {
            console.log("setting to empty");
            formCox = '';
        }
        // add the selected class/hull button selections
        setContactDetails({
            ...contactDetails,
            boatClass: selectedBoatClass,
            hullSize: selectedHullSize,
            cox: formCox
        });

        // submit the form
        await setFormSubmitted(true);
        // revert the form to default state;
        await setFormSubmitted(false);
    };

    useEffect(() => {
        // clear the form if it's successful
        if (formSubmission[0].success === true) {
            formSubmission[0].success = false;
            setContactDetails(defaultContactDetails);
        }
    }, [formSubmission]);

    return (
        <div className="c-product-contact">
            <div className="row">
                <div className="col">
                    <ProductContactTitle brandName={brandName} brandLogo={brandLogo} />
                </div>
            </div>

            {productType === 'boat' && (
                <>
                    <div className="row">
                        <div className="col">
                            <div className="c-product-contact__option">
                                <div className="c-product-contact__option-title">{t("product.contact.class-title")}</div>

                                <div className="c-product-contact__buttons">
                                    {classInfo.map((classItem, index) => {
                                        const { name, id } = classItem.class;

                                        return (
                                            <Button
                                                className={name === selectedBoatClass ? "xs-mr-10 c-button--black" : "xs-mr-10 c-button--outline-black"}
                                                key={id}
                                                onClick={() => setBoatClassValues(index)}
                                                text={name}
                                            />
                                        )
                                    })}
                                </div>
                            </div>
                        </div>
                    </div>

                    {(process.env.GATSBY_THEME != "King") && (selectedBoatClass === "Four/Quad") && (productName !== "Cobra SE") && (
                        <div className="row">
                            <div className="col">
                                <div className="c-product-contact__option">
                                    <div className="c-product-contact__option-title">{t("product.contact.cox-title")}</div>

                                    <div className="c-product-contact__buttons">
                                        <Button
                                            className={selectedCox === 'true' ? 'c-button xs-mr-10 c-button--black' : 'c-button xs-mr-10 c-button--outline-black'}
                                            key='cox-true'
                                            onClick={() => setSelectedCox('true')}
                                            text={t("product.contact.cox-true")}
                                        />
                                        <Button
                                            className={selectedCox === 'false' ? 'c-button xs-mr-10 c-button--black' : 'c-button xs-mr-10 c-button--outline-black'}
                                            key='cox-false'
                                            onClick={() => setSelectedCox('false')}
                                            text={t("product.contact.cox-false")}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    )}

                    <div className="row c-product-contact__hull-shape">
                        <div className="col">
                            <div className="c-product-contact__option">
                                <div className="c-product-contact__option-title">{t("product.contact.hull-title")}</div>

                                <div className="c-product-contact__buttons">
                                    <Button
                                        className={selectedHullSize === 'advise' ? 'c-button xs-mr-10 c-button--black' : 'c-button xs-mr-10 c-button--outline-black'}
                                        key='advise'
                                        onClick={() => setSelectedHullSize('advise')}
                                        text={t("product.contact.button-text")}
                                    />

                                    {availableHullShapes.map(item => {
                                        const { title, id } = item;

                                        return (
                                            <Button
                                                className={title === selectedHullSize ? "xs-mr-10 c-button--black" : "xs-mr-10 c-button--outline-black"}
                                                key={id}
                                                onClick={() => setSelectedHullSize(item.title)}
                                                text={title}
                                            />
                                        );
                                    })}
                                </div>
                            </div>
                        </div>
                    </div>
                </>
            )}
            <form onSubmit={handleSubmit} className="c-form">
                <ContactFormFields
                    contactDetails={contactDetails}
                    handleInputChange={handleInputChange}
                />
            </form>
        </div>
    );
};

export default withTranslation()(ContactForm);