import React, { useContext, useState, useEffect } from 'react';
import Modal from 'react-modal';
import Img from "gatsby-image";
import {withTranslation} from "react-i18next";

import ContactForm from './component.product-contact';
import ThemeContext from "../../context/context.theme";
import Button from '../component.button';

// Bind modal to the appElement (http://reactcommunity.org/react-modal/accessibility/)
Modal.setAppElement('#___gatsby');

const ProductEnquiry = ({ productName, comesWith, whoShouldBuyIt, classInfo, brandLogo, brandName, modalIsOpen, setModalIsOpen, productType, activeClass, t }) => {
    const [boatClass, setBoatClass] = useState(0);
    const [selectedBoatClass, setSelectedBoatClass] = useState(null);
    const [selectedHullSize, setSelectedHullSize] = useState('');
    const defaultContentVisibility = [];
    const [contentVisibility, setContentVisibility] = useState(defaultContentVisibility);
    const theme = useContext(ThemeContext);

    // by default load the available shapes for the first boat class
    const hullShapes = productType === 'boat' ? classInfo[boatClass].availableHullShapes : [];

    function setBoatClassValues(index) {
        // this set's the button visibility
        setBoatClass(index);
        // this set's the text value for the contact form
        setSelectedBoatClass(classInfo[index].class.name);
        // this reverts any changes for the hull shape content visibility
        setContentVisibility(defaultContentVisibility);
        // reset hull shape so it doesn't stay on one that's not available for this boat class.
        setSelectedHullSize('');
    }

    function toggleContent(value) {
        // duplicate the current array to prevent the original being reverted
        let updatedContentVisibility = contentVisibility.map((x) => x);
        const index = updatedContentVisibility.indexOf(value);

        if (index === -1) {
            // if the current value doesn't exist, add it so the content is visible
            updatedContentVisibility.push(value);
        } else {
            // if the current value is present, remove it so the content is no longer visible
            updatedContentVisibility.splice(index, 1);
        }

        // update the state
        setContentVisibility(updatedContentVisibility);
    }

    // Component did mount
    useEffect(() => {
        if(activeClass) {
            let i;          
            for (i in classInfo) {
                if(classInfo[i].class.name == activeClass) {
                    setBoatClassValues(parseInt(i));
                    break;
                }
            }
        } else if (classInfo) {
            // Set first class to be active
            setSelectedBoatClass(0);
        }
    }, [activeClass]);


    return (
        <>
            <div className="u-bg--waved-rowing u-bg--fixed">
                <div className="container xs-pt-20 xs-pb-20">
                    <div className="u-bg--white">
                        <div className="row">
                            <div className="col-lg-10 offset-lg-1">
                                <div className="c-product-enquiry">
                                    <div className="row">
                                        <div className="col">
                                            <h2 className="c-product-enquiry__title">{t("product.enquiry.title")}</h2>
                                        </div>
                                    </div>

                                    <div className="row">
                                        {whoShouldBuyIt &&
                                        <div className={comesWith ? "col-lg-6" : "col"}>
                                            <h3 className="c-product-enquiry__sub-title">{t("product.enquiry.who-should-sub-title")}</h3>
                                            <div className="o-content" dangerouslySetInnerHTML={{ __html: whoShouldBuyIt }}></div>
                                        </div>
                                        }

                                        {comesWith &&
                                        <div className={whoShouldBuyIt ? "col-lg-5 offset-lg-1" : "col"} >
                                            <h3 className="c-product-enquiry__sub-title">{t("product.enquiry.comes-with-sub-title")}</h3>
                                            <ul className="c-product-enquiry__list">
                                                {comesWith.map((comeWith, index) => (
                                                    <li key={index} className="c-product-enquiry__list-item">{comeWith.item}</li>
                                                ))}
                                            </ul>
                                        </div>
                                        }
                                    </div>

                                    {productType === 'boat' && (
                                        <>
                                            <div className="row xs-mt-40">
                                                <div className="col-md-12">
                                                    <div className="c-product-enquiry-form__container c-product-enquiry-form__container--inline">
                                                        <p className="c-product-enquiry-form__title">{t("product.enquiry.class-title")}</p>
                                                        <div className="c-product-enquiry-form__buttons">
                                                            {classInfo && classInfo.map((info, index) => (
                                                                <Button
                                                                    key={index}
                                                                    small
                                                                    black={index === boatClass ? true : false}
                                                                    outlineBlack={index === boatClass ? false : true}
                                                                    onClick={() => setBoatClassValues(index)}
                                                                    text={info.class.name}
                                                                />
                                                            ))}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
    
                                            <div className="row c-product-contact__hull-shape">
                                                <div className="col-md-12">
                                                    <div
                                                        className="c-product-enquiry-form__container c-product-enquiry-form__container__container--no-border">
                                                        <p className="c-product-enquiry-form__title">{t("product.enquiry.hull-title")}</p>

                                                        <ul className="c-product-enquiry-form__options">
                                                            <li
                                                                onClick={() => setSelectedHullSize('advise')}
                                                                className={selectedHullSize === 'advise' ? 'c-product-enquiry-form__option c-product-enquiry-form__option--active' : 'c-product-enquiry-form__option'}
                                                            >
                                                                <div className="c-product-enquiry-form__dropdown-head">
                                                                    <div className="c-product-enquiry-form__dropdown-selector">
                                                                        <button className="c-product-enquiry-form__dropdown-icon">
                                                                            <i className={selectedHullSize === 'advise' ? 'fal fa-check-circle' : 'fal fa-circle'}></i>
                                                                        </button>
                                                                        <div>
                                                                            <p className="c-product-enquiry-form__option-title">{t("product.enquiry.option-title")}</p>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </li>

                                                            {hullShapes && hullShapes.map((shape, index) => (
                                                                <li
                                                                    onClick={() => setSelectedHullSize(shape.title)}
                                                                    key={index}
                                                                    className={selectedHullSize === shape.title ? 'c-product-enquiry-form__option c-product-enquiry-form__option--active' : 'c-product-enquiry-form__option'}
                                                                >
                                                                    <div className="c-product-enquiry-form__dropdown-head">
                                                                        <div
                                                                            className="c-product-enquiry-form__dropdown-selector">
                                                                            <button className="c-product-enquiry-form__dropdown-icon">
                                                                                <i className={selectedHullSize === shape.title ? 'fal fa-check-circle' : 'fal fa-circle'}></i>
                                                                            </button>
                                                                            <div>
                                                                                <p className="c-product-enquiry-form__option-title">{shape.title}</p>
                                                                                <button
                                                                                    className="c-product-enquiry-form__learn-more"
                                                                                    onClick={() => toggleContent(index)}
                                                                                    aria-label={`${t("product.enquiry.learn-label")} ${shape.title}`}
                                                                                >
                                                                                    <span>{t("product.enquiry.learn-text")}</span>
                                                                                    <i className={contentVisibility.includes(index) === true ? 'fal fa-chevron-up' : 'fal fa-chevron-down'}></i>
                                                                                </button>
                                                                            </div>
                                                                        </div>
                                                                        <div className="c-product-enquiry-form__best-for">
                                                                            {t("product.enquiry.best-for-a")}: {shape.ACFHullShapeBlockFields.bestFor ? shape.ACFHullShapeBlockFields.bestFor : t("product.enquiry.best-for-b")}
                                                                        </div>
                                                                    </div>
                                                                    <div
                                                                        className={contentVisibility.includes(index) === true ? 'c-product-enquiry-form__dropdown-content c-product-enquiry-form__dropdown-content--active' : 'c-product-enquiry-form__dropdown-content'}>
                                                                        {shape.featuredImage && (
                                                                            <div
                                                                                className="c-product-enquiry-form__dropdown-image">
                                                                                <Img
                                                                                    className="c-product-enquiry-form__dropdown-image--rounded"
                                                                                    key={index}
                                                                                    fixed={shape.featuredImage.node.imageFile.childImageSharp.fixed}
                                                                                    alt={shape.featuredImage.altText} />
                                                                            </div>
                                                                        )}
                                                                        <div
                                                                            className='c-product-enquiry-form__dropdown-wysiwyg'
                                                                            dangerouslySetInnerHTML={{ __html: shape.ACFHullShapeBlockFields.description }}></div>
                                                                    </div>
                                                                </li>
                                                            ))}
                                                        </ul>
                                                    </div>
                                                </div>
                                            </div>
                                        </>
                                    )}

                                    <div className="c-product-enquiry-form__button">
                                        <Button large text={t("product.enquiry.contact-button-label")} onClick={() => setModalIsOpen(true)} />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <Modal
                className={theme.loading ? 'c-modal c-modal--loading' : 'c-modal'}
                overlayClassName="c-modal__overlay"
                isOpen={modalIsOpen}
                onRequestClose={() => setModalIsOpen(false)}
                contentLabel={t("product.enquiry.modal-label")}
            >
                <div className="c-modal__head">
                    <button className='c-button c-button--close' onClick={() => setModalIsOpen(false)} aria-label={t("product.enquiry.close-label")}><i className="fal fa-times"></i></button>
                </div>

                <ContactForm
                    productName={productName}
                    classInfo={classInfo}
                    boatClass={boatClass}
                    setBoatClassValues={setBoatClassValues}
                    selectedBoatClass={selectedBoatClass}
                    selectedHullSize={selectedHullSize}
                    setSelectedHullSize={setSelectedHullSize}
                    brandLogo={brandLogo}
                    brandName={brandName}
                    productType={productType}
                />
            </Modal>
        </>
    );
};

export default withTranslation()(ProductEnquiry);
