import React from 'react';
import {withTranslation} from "react-i18next";

import ProductOverview from '../component.product-overview';

const ProductsList = ({ products, t, language }) => (
    <ul className="c-products-list">
        {(products.length > 0) && products.map((product, index) => (
            <li key={index} className="c-products-list__item">
                <ProductOverview details={product} />
            </li>
        ))}

        {(products.length === 0) && (
            <li className="xs-mb-40">{t("plp.products-list.no-products")}</li>
        )}
    </ul>
);

export default withTranslation()(ProductsList);
