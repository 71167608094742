import React from 'react';
import {withTranslation} from "react-i18next";

import Button from '../component.button';

const ProductInfoBanner = ({ title, setModalIsOpen, localPrice, classInfo, taxMessage, t }) => (
    <div className="c-product-info-banner u-bg--pickled-bluewood">
        <div className="container">
            <div className="row">
                <div className="col">
                    <div className={localPrice ? "c-product-info-banner__content c-product-info-banner__content--pricing" : "c-product-info-banner__content"}
                    >
                        <h1 className="c-product-info-banner__title" dangerouslySetInnerHTML={{ __html: title }}></h1>
                        {localPrice && 
                            <div className="c-product-info-banner__priceButton">
                                <div className="c-product-info-banner__pricing"><span className="c-product-info-banner__classInfo">{classInfo[0].class.name} {t("product.pricing.price-from")} </span><span className="c-product-info-banner__priceAmount">{localPrice}</span>
                                {taxMessage &&
                                    <span className="c-product-info-banner__tax">{taxMessage}</span>
                                }
                                </div>
                                <Button autoWidth text="Enquire now" onClick={() => setModalIsOpen(true)} />
                            </div>
                        }
                        {!localPrice && 
                            <Button autoWidth text={t("product.info-banner.button-text")} onClick={() => setModalIsOpen(true)} />
                        }
                    </div>
                </div>
            </div>
        </div>
    </div>
);

export default withTranslation()(ProductInfoBanner);
