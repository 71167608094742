import React, { useState, useContext, useEffect } from 'react';
import { graphql } from 'gatsby';

import Layout from '../components/component.layout';
import SEO from '../components/component.seo';
import Info from '../components/component.info';

import ThemeContext from "../context/context.theme";
import ProductInfoBanner from '../components/product/component.product-info-banner';
import ProductDetails from '../components/product/component.product-details';
// import ProductCurrencySwitcher from '../components/product/component.product-currency-switcher';
import ProductEnquiry from '../components/product/component.product-enquiry';
import ProductRelated from '../components/product/component.product-related';
import ProductSpec from '../components/product/component.product-spec';
import ProductHero from '../components/product/component.product-hero';
import ProductClasses from '../components/product/component.product-classes';
import ProductTitle from '../components/product/component.product-title';
import CookieNotice from "../components/component.cookie-notice";
import Axios from "axios";

const ProductTemplate = ({ data, location }) => {
    const theme = useContext(ThemeContext);
    const [modalIsOpen, setModalIsOpen] = useState(false);
    const [localPrice, setLocalPrice] = useState(null);
    const [localTaxmessage, setLocalTaxmessage] = useState(null);
    const [selectedCurrency, setSelectedCurrency] = useState(null);
    const product = data.wordpress.product;
    const { language, translations, slug, uri } = data.wordpress.product;
    const seo = data.wordpress.product.seo;
    const productDetails = data.wordpress.product.ACFProductBlockFields;

    let productInitialActiveClass = (product.ACFProductBlockFields.classesInformation ? product.ACFProductBlockFields.classesInformation[0].class.name : null);

    const [activeTab, setActiveTab] = useState(productInitialActiveClass);
    const [minTabHeight, setminTabHeight] = useState(0);

    let pageLanguage = 'EN';
    if(typeof language !== "undefined") {
        pageLanguage = language.code;
    }

    let taxMessage = '';
    
    function returnPricing(continentName, countryName) {
        let thisRegionNameLower = '';
        let returnPricing = null;
        let foundContinentPricing = '';
        let foundCountryPricing = '';
        
        if(countryName) {
            countryName = countryName.toLowerCase();
        }
        if(continentName) {
            continentName = continentName.toLowerCase();
        }

        if(productDetails.pricingPerCountry != null) {
            for(var pricingCountry in productDetails.pricingPerCountry) {
                thisRegionNameLower = productDetails.pricingPerCountry[pricingCountry].country[0].name.toLowerCase();
                if(thisRegionNameLower == countryName) {
                    foundCountryPricing = productDetails.pricingPerCountry[pricingCountry].price;
                    if(typeof productDetails.pricingPerCountry[pricingCountry].country[0].pricingCountries.taxMessage !== "undefined") {
                        taxMessage = productDetails.pricingPerCountry[pricingCountry].country[0].pricingCountries.taxMessage;
                    }
                }
                if (thisRegionNameLower == continentName) {
                    foundContinentPricing = productDetails.pricingPerCountry[pricingCountry].price;
                    if(typeof productDetails.pricingPerCountry[pricingCountry].country[0].pricingCountries.taxMessage !== "undefined") {
                        taxMessage = productDetails.pricingPerCountry[pricingCountry].country[0].pricingCountries.taxMessage;
                    }
                }
            }
        }
        // country takes precedence:
        if(foundCountryPricing != '') {
            returnPricing = foundCountryPricing;
        } else if(foundContinentPricing != '') {
            returnPricing = foundContinentPricing;
        }

        if(returnPricing === null) {
            // https://ipapi.co/api/
            Axios.get("https://ipapi.co/json/")
            .then(result => {
                // https://datahub.io/core/continent-codes
                const continentCodeMappings = {
                    "AF": "Africa",
                    "NA": "North America",
                    "OC": "Oceania",
                    "AN": "Antarctica",
                    "AS": "Asia",
                    "EU": "Europe",
                    "SA": "South America"
                }
                if (typeof window !== 'undefined' && window) {
                    localStorage.setItem('storedCountry',result.data.country_name);
                    localStorage.setItem('storedContinent',continentCodeMappings[result.data.continent_code]);
                }
                setSelectedCurrency(result.data.country_name);
                returnPricing(continentCodeMappings[result.data.continent_code],result.data.country_name);

            })
            .catch(err => {
                // setLocalPrice(null);
            })


        } else {
            setLocalTaxmessage(taxMessage);
            setLocalPrice(returnPricing);
        }
    }

    useEffect(() => {
        // check if stored in localStorage:
        let requiresGeoIPLookup = true;
        if (typeof window !== 'undefined' && window) {
            if (localStorage.getItem("storedCountry") !== null) {
                requiresGeoIPLookup = false;
                if (localStorage.getItem("storedContinent") !== null) {
                    returnPricing(localStorage.getItem("storedContinent"), localStorage.getItem("storedCountry"));
                } else {
                    returnPricing(null, localStorage.getItem("storedCountry"));
                }
                setSelectedCurrency(localStorage.getItem("storedCountry"));
            }
        }

        if(productDetails.pricingPerCountry == null) {
            // don't need to test location:
            requiresGeoIPLookup = false;
        }

        if(requiresGeoIPLookup) {
            if (typeof geoip2 !== 'undefined') {
              geoip2.country(onMaxMindSuccess, onMaxMindError);
            } else {
                const externalScriptTimer = window.setInterval(() => {
                    if (typeof geoip2 !== 'undefined') {
                      geoip2.country(onMaxMindSuccess, onMaxMindError);
                      window.clearInterval(externalScriptTimer);
                    }
                }, 500)

                return () => {
                  window.clearInterval(externalScriptTimer);
                }
            }
        }

        if(location.state) {
            if(location.state.activeClass) {
                productInitialActiveClass = location.state.activeClass;
            }
        }
        if (typeof window !== 'undefined' && window) {
            const queryString = new URLSearchParams(location.search);
            const thisBoatClass = queryString.get("class");
            if(thisBoatClass !== null) {
                // validate it:
                let i, thisClass;
                for(i in product.ACFProductBlockFields.classesInformation) {
                    thisClass = product.ACFProductBlockFields.classesInformation[i].class.name;
                    if (thisClass.toLowerCase() == thisBoatClass.toLowerCase()) {
                        productInitialActiveClass = thisClass;
                        break;
                    } else if (thisClass.toLowerCase() == thisBoatClass.replace("-","/").toLowerCase()){
                        // allow Pair-Double instead of Pair%2FDouble so it's more human readable
                        productInitialActiveClass = thisClass;
                        break;
                    }
                }
            }
        }
        setActiveTab(productInitialActiveClass);

    }, []);

    useEffect(() => {
        returnPricing(null, selectedCurrency);
        if (typeof window !== 'undefined' && window) {
            localStorage.setItem('storedCountry',selectedCurrency);
        }
    }, [selectedCurrency]);

    return (
        <div>
            <Layout language={language} translations={translations}>
                <SEO title={product.title} seo={seo} image={product.featuredImage} translations={translations} slug={slug} language={language} />

                <ProductInfoBanner title={product.title} setModalIsOpen={setModalIsOpen} localPrice={localPrice} classInfo={product.ACFProductBlockFields.classesInformation} taxMessage={localTaxmessage} />
                {/*
                <ProductCurrencySwitcher pricing={productDetails.pricingPerCountry} selectedCurrency={selectedCurrency} setSelectedCurrency={setSelectedCurrency} />
                */}

                {productDetails.images &&
                    <div className={theme.screenType === 'desktop' ? "container" : ""}>
                        <ProductHero images={productDetails.images} activeClass={activeTab} />
                    </div>
                }

                <div className="container">
                    <ProductTitle brandName={productDetails.brandName} shortDescription={productDetails.shortDescription} brandLogo={productDetails.brandLogo} />
                    
                    {product.ACFProductBlockFields.classesInformation &&
                        <div className="c-tabs">
                            <ol className="c-tabs__list">
                                {product.ACFProductBlockFields.classesInformation && product.ACFProductBlockFields.classesInformation.map((classInfo, index) => {
                                    const name = classInfo.class.name;
                                    return (
                                        <li key={index} className={activeTab === name ? "c-tabs__item c-tabs__item--active" : "c-tabs__item"} key={name} onClick={() => {
                                            setActiveTab(name);
                                            setminTabHeight(tabContent.current.clientHeight);
                                        }}>
                                            {name}
                                        </li>
                                    );
                                })}
                            </ol>
                            <ProductClasses
                                classes={product.ACFProductBlockFields.classesInformation}
                                activeTab={activeTab}
                                minTabHeight={minTabHeight}
                            />
                        </div>
                    }
                </div>

                <ProductEnquiry
                    productName={product.title}
                    comesWith={productDetails.comesWith}
                    whoShouldBuyIt={productDetails.whoShouldBuyIt}
                    classInfo={product.ACFProductBlockFields.classesInformation}
                    activeClass={activeTab}
                    brandName={productDetails.brandName}
                    brandLogo={productDetails.brandLogo}
                    modalIsOpen={modalIsOpen}
                    setModalIsOpen={setModalIsOpen}
                    productType={productDetails.productType}
                />

                <div className="container">
                    {productDetails.tabContent &&
                        <ProductDetails content={productDetails.tabContent} />
                    }
                    <ProductSpec
                        productType={productDetails.productType}
                        weightOptions={productDetails.weightSpecification}
                        lengthOptions={productDetails.lengthSpecification}
                        riggerOptions={productDetails.riggerOptions}
                        shoeOptions={productDetails.shoeOptions}
                        language={pageLanguage}
                    />
                </div>

                {productDetails.relatedProducts &&
                    <ProductRelated products={productDetails.relatedProducts} />
                }

                <Info language={pageLanguage} />
                <CookieNotice />
            </Layout>
        </div>
    );
};

export const query = graphql`
    query($id: ID!) {
        wordpress {
            product(id: $id) {
                title
                seo {
                    ...SeoPostFields
                }
                slug
                uri
                language {
                    code
                    locale
                }
                translations {
                    # id
                    uri
                    language {
                        code
                        locale
                    }
                }
                featuredImage {
                    node {
                        altText
                        sourceUrl
                        imageFile {
                            childImageSharp {
                                fluid(maxWidth: 1140) {
                                    ...GatsbyImageSharpFluid_withWebp_tracedSVG
                                }
                            }
                        }
                    }
                }
                ACFProductBlockFields {
                    productType
                    tabContent {
                        content
                        title
                        image {
                            altText
                            sourceUrl
                            imageFile {
                                childImageSharp {
                                    fluid(maxWidth: 550) {
                                        ...GatsbyImageSharpFluid_withWebp_tracedSVG
                                    }
                                }
                            }
                        }
                    }
                    whoShouldBuyIt
                    shortDescription
                    descriptionExcerpt
                    brandName
                    brandLogo {
                        altText
                        sourceUrl
                        imageFile {
                            childImageSharp {
                                fluid(maxWidth: 70) {
                                    ...GatsbyImageSharpFluid_withWebp_tracedSVG
                                }
                            }
                        }
                    }
                    comesWith {
                        item
                    }
                    classesInformation {
                        weight
                        stiffness
                        stability
                        durability
                        class {
                            ... on WORDPRESS_Class {
                                name
                                id
                            }
                        }
                        availableHullShapes {
                            ...on WORDPRESS_HullShape {
                                title
                                # content
                                ACFHullShapeBlockFields {
                                    bestFor
                                    description
                                }
                                featuredImage {
                                    node {
                                        altText
                                        sourceUrl
                                        imageFile {
                                            childImageSharp {
                                                fixed(width: 100, height: 100) {
                                                    ...GatsbyImageSharpFixed_withWebp_tracedSVG
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        }
                        sliderImages {
                            image {
                                altText
                                sourceUrl
                                imageFile {
                                    childImageSharp {
                                        fluid(maxWidth: 2340) {
                                            ...GatsbyImageSharpFluid_withWebp_tracedSVG
                                            presentationWidth
                                        }
                                    }
                                }
                            }
                        }
                    }
                    images {
                        image {
                            altText
                            sourceUrl
                            imageFile {
                                childImageSharp {
                                    fluid(maxWidth: 2340) {
                                        ...GatsbyImageSharpFluid_withWebp_tracedSVG
                                        presentationWidth
                                    }
                                }
                            }
                        }
                        imageClass {
                          name
                        }
                    }
                    weightSpecification {
                        title
                        row {
                            label
                            crewWeightRangeKg
                            crewWeightRangeLbs
                            averageAthleteWeightKg
                            averageAthleteWeightLbs
                        }
                    }
                    lengthSpecification {
                        class {
                            ...on WORDPRESS_Class {
                                name
                            }
                        }
                        hullWidth
                        length
                    }
                    riggerOptions {
                        name
                        image {
                            altText
                            sourceUrl
                            imageFile {
                                childImageSharp {
                                    fluid(maxWidth: 2340) {
                                        ...GatsbyImageSharpFluid_withWebp_tracedSVG
                                    }
                                }
                            }
                        }
                    }
                    shoeOptions {
                        name
                        image {
                            altText
                            sourceUrl
                            imageFile {
                                childImageSharp {
                                    fluid(maxWidth: 2340) {
                                        ...GatsbyImageSharpFluid_withWebp_tracedSVG
                                    }
                                }
                            }
                        }
                    }
                    pricingPerCountry {
                        price
                        country {
                            name
                            pricingCountries {
                                currencySymbol
                                taxMessage
                            }
                        }
                    }
                }
            }
        }
    }
`;

export default ProductTemplate;
