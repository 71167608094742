import React from 'react';
import { graphql, useStaticQuery } from "gatsby";

const DeliveryFinancing = ({language}) => {
    const data = useStaticQuery(graphql`

        fragment financeContent on WORDPRESS_GlobalContent {
            ACFDeliveryAndFinanceBlockFields {
              content
            }
        }

        query Content {
            wordpress {
                EN: globalContent(idType: SLUG, id: "delivery-finance-en") {
                    ...financeContent
                }
                FR: globalContent(idType: SLUG, id: "delivery-finance-fr") {
                    ...financeContent
                }
                ES: globalContent(idType: SLUG, id: "delivery-finance-es") {
                    ...financeContent
                }
                ZH: globalContent(idType: SLUG, id: "delivery-finance-zh") {
                    ...financeContent
                }
                DE: globalContent(idType: SLUG, id: "delivery-finance-de") {
                    ...financeContent
                }
                PL: globalContent(idType: SLUG, id: "delivery-finance-pl") {
                    ...financeContent
                }
            }
        }
    `);
    

    let content;
    // language is taken from the page's language code
    if(typeof data.wordpress[language] !== "undefined") {
        content = data.wordpress[language].ACFDeliveryAndFinanceBlockFields.content;
    } else {
        content = data.wordpress['EN'].ACFDeliveryAndFinanceBlockFields.content;
    } 

    return (
        <>
            {content && 
                <div className="o-content" dangerouslySetInnerHTML={{ __html: content }}></div>
            }
        </>
    );
}

export default DeliveryFinancing;
