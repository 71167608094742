import React from 'react';
import Img from "gatsby-image";

const ProductContactTitle = ({ brandName, brandLogo, shortDescription }) => (
    <div className="c-product-contact-title">
        {brandLogo && (
            <div className="c-product-contact-title__image">
                <Img fluid={brandLogo.imageFile.childImageSharp.fluid} alt={brandLogo.altText} />
            </div>
        )}
        <div className="c-product-contact-title__text">
            <h1 className="c-product-contact-title__title" dangerouslySetInnerHTML={{ __html: brandName }}></h1>
        </div>
    </div>
);

export default ProductContactTitle;
