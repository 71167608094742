import React from 'react';
import Img from "gatsby-image";

const ProductTitle = ({ brandName, brandLogo, shortDescription }) => (
    <div className="c-product-title">
        <div className="c-product-title__title-wrapper">
            {brandLogo && brandLogo.imageFile && (
                <Img className="c-product-title__image" fluid={brandLogo.imageFile.childImageSharp.fluid} alt={brandLogo.altText} />
            )}
            <h1 className="c-product-title__title" dangerouslySetInnerHTML={{ __html: brandName }}></h1>
        </div>

        <p className="c-product-title__desc" dangerouslySetInnerHTML={{ __html: shortDescription }}></p>
    </div>
);

export default ProductTitle;
