import React, { useState, useEffect } from 'react';
import {withTranslation} from "react-i18next";

import WeightDimensions from "./component.product-spec-weight-dimensions";
import RiggerOptions from "./component.product-spec-rigger-options";
import DeliveryFinancing from "./component.product-spec-delivery-financing";
import Faqs from "./component.product-spec-faqs";
import Testimonials from "./component.product-spec-testimonials";
import ShoeOptions from "./component.product-spec-shoe-options";
import deepMerge from '../../utilties/deepMerge';

const ProductSpec = ({ productType, riggerOptions, shoeOptions, weightOptions, lengthOptions, t, language }) => {
    const [accordion, toggleAccordion] = useState(null);

    /**
     * Prepares the data
     *
     * @param {*} { riggerOptions, shoeOptions, weightOptions, lengthOptions }
     * @returns
     */
    function populateComponents({ riggerOptions, shoeOptions, weightOptions, lengthOptions }) {
        const components = {};

        if (weightOptions) {
            components.weight = {
                label: t("product.spec.dimensions-label"),
                component: WeightDimensions,
                props: {
                    weightOptions: weightOptions,
                    lengthOptions: lengthOptions
                },
                active: false
            };
        }

        if (riggerOptions) {
            components.rigger = {
                label: t("product.spec.rigger-label"),
                component: RiggerOptions,
                props: {
                    riggerOptions: riggerOptions,
                },
                active: false
            };
        }

        if (shoeOptions) {
            components.shoe = {
                label: t("product.spec.shoe-label"),
                component: ShoeOptions,
                props: {
                    shoeOptions: shoeOptions,
                },
                active: false
            };
        }

        components.delivery = {
            label: t("product.spec.delivery-label"),
            component: DeliveryFinancing,
            props: {
                language: language
            },
            active: false
        };

        components.faqs = {
            label: t("product.spec.faqs-label"),
            component: Faqs,
            props: {
                language: language
            },
            active: false
        };

        components.testimonials = {
            label: t("product.spec.testimonials-label"),
            component: Testimonials,
            props: {
                language: language
            },
            active: false
        };

        return components;
    }

    const components = populateComponents({
        riggerOptions,
        shoeOptions,
        weightOptions,
        lengthOptions
    });

    useEffect(() => {
        toggleAccordion(components);
    }, []);

    function updateAccordionState(key) {
        const updatedSection = {
            [key]: { active: !accordion[key].active }
        };
        const updatedAccordion = deepMerge(accordion, updatedSection);
        toggleAccordion(updatedAccordion);
    }

    const title = productType === 'boat' ? t("product.spec.boat-spec-title") : t("product.spec.spec-title");

    if (accordion) {
        return (
            <div className="container">
                <div className="c-accordion">
                    <div className="row">
                        <div className="col-md-10 offset-md-1 col-lg-8 offset-lg-2">
                            <h2 className="c-accordion__header">{title}</h2>

                            {Object.keys(accordion).map(key => {
                                const { component: Component, props, label } = accordion[key];

                                return (
                                    <div
                                        key={key}
                                        className={(accordion[key].active === true ? `c-accordion__item c-accordion__item--active` : `c-accordion__item`)}
                                    >
                                        <button className="c-accordion__title" onClick={() => updateAccordionState(key)}>
                                            {label}
                                        </button>
                                        <div className="c-accordion__content">
                                            <div className="c-accordion__wrapper">
                                                <Component {...props} />
                                            </div>
                                        </div>
                                    </div>
                                );
                            })}
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    return (<></>);

}
export default withTranslation()(ProductSpec);
