import React, { useEffect, useState } from 'react';
import Img from "gatsby-image";
import {withTranslation} from "react-i18next";

/**
 * Creates an id to identify tabs
 * Removes spaces and makes lower case.
 *
 * @param {*} str
 * @returns
 */
function createId(str) {
    return str.replace(' ', '').toLowerCase();
}

const ProductDetails = ({ content, t }) => {
    const [activeTab, setActiveTab] = useState(null);

    useEffect(() => {
        const initalTab = createId(content[0].title);
        setActiveTab(initalTab);
    }, []);
    
    return (
        <div className="c-product-details">
            <h2 className="c-product-details__header">{t("product.details.title")}&hellip;</h2>
        
            <ul className="c-product-details__items">
                {content.map(tab => {
                    const currentTab = createId(tab.title);

                    return (
                        <li 
                            className={activeTab === currentTab ? "c-product-details__item c-product-details__item--active" : "c-product-details__item"}
                            key={currentTab}
                        >
                            <button 
                                className="c-product-details__item-button"
                                onClick={() => setActiveTab(currentTab)}
                            >
                                {tab.title}
                            </button>
                        </li>
                    );
                })}
            </ul>
            
            <div className="c-product-details__contents">
                {content.map((tab, index) => {
                    const currentTab = createId(tab.title);
                    
                    if (tab.image) {
                        return (
                            <div
                                key={index}
                                className={activeTab === currentTab ? "c-product-details__content c-product-details__content--active" : "c-product-details__content"}
                            >
                                <div className="row">
                                    <div className="col-md-6">
                                        <div dangerouslySetInnerHTML={{__html: tab.content}}></div>
                                    </div>
                                    <div className="col-md-6">
                                        {tab.image &&
                                            <Img fluid={tab.image.imageFile.childImageSharp.fluid} alt={tab.image.altText} />
                                        }
                                    </div>
                                </div>
                            </div>
                        );
                    } else {
                        return (
                            <div
                                key={index}
                                className={activeTab === currentTab ? "c-product-details__content c-product-details__content--active" : "c-product-details__content"}
                            >
                                <div className="row">
                                    <div className="col">
                                        <div dangerouslySetInnerHTML={{__html: tab.content}}></div>
                                    </div>
                                </div>
                            </div>
                        );
                    }
                })}
            </div>
        </div>
    );
}

export default withTranslation()(ProductDetails);
