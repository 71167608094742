import React from 'react';
import Img from 'gatsby-image';
import Slider from 'react-slick';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import NonStretchedImage from '../component.non-stretched-image';

const sliderSettings = {
    autoplay: true,
    dots: true,
    infinite: true,
    speed: 2000,
    fade: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    adaptiveHeight: true,
};

const ProductHero = ({ images, activeClass }) => {
    // create multiple sliders collating the images by class:
    let relevantImage, imageClassName;
    return (
        <Slider className="c-hero c-hero--product u-text--center" {...sliderSettings}>
            {images.map((image, index) => {
                if (image.image) {
                    relevantImage = false;
                    // if it has a class assigned, check it's the active one:
                    if (image.imageClass) {
                        for (imageClassName in image.imageClass) {
                            if(image.imageClass[imageClassName].name == activeClass) {
                                relevantImage = true;
                                break;
                            }
                        }
                    } else {
                        // this image doesn't have a class assigned, so use it for all sliders:
                        relevantImage = true;
                    }
                    if(relevantImage) {
                        return (
                            <div><NonStretchedImage key={index} fluid={image.image.imageFile.childImageSharp.fluid} alt={image.altText} /></div>
                        );
                    }
                }
                return false;
            })}
        </Slider>
    );
}

export default ProductHero;