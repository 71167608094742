import React, { useState, useRef } from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import {withTranslation} from "react-i18next";

import Slider from 'react-slick';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import BackgroundImage from 'gatsby-background-image';

import { getStatus } from '../../utilties/helpers';
import NonStretchedImage from '../component.non-stretched-image';

const ProductClasses = ({ classes, activeTab, minTabHeight, t }) => {
    // Set up tabs
    // Set the initial tab to the selected class on the PLP or the first class if not specified
    

    const tabContent = useRef(null);

    const imageData = useStaticQuery(graphql`
        query {
            file(relativePath: { eq: "textured-background.jpg" }) {
                childImageSharp {
                    fluid(maxWidth: 1920) {
                        ...GatsbyImageSharpFluid_withWebp_tracedSVG
                    }
                }
            }
        }
    `);

    const sliderSettings = {
        dots: true,
        adaptiveHeight: true,
        infinite: true,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true,
        fade: true,
        speed: 1750,
        autoplaySpeed: 1750,
        cssEase: 'cubic-bezier(0.47, 0, 0.745, 0.715)',
    };

    return (
        
        <div ref={tabContent}>
            {classes && classes.map((classInfo, index) => {
                // Get status elements to show
                const name = classInfo.class.name;
                const durabilityStatus = getStatus({ full: 5, amount: classInfo.durability, title: t("product.classes.durability-title") });
                const stabilityStatus = getStatus({ full: 5, amount: classInfo.stability, title: t("product.classes.stability-title") });
                const stiffnessStatus = getStatus({ full: 5, amount: classInfo.stiffness, title: t("product.classes.stiffness-title") });
                const weightStatus = getStatus({ full: 5, amount: classInfo.weight, title: t("product.classes.weight-title") });

                return (
                    <div key={index} style={{ minHeight: `${minTabHeight}px` }} className={activeTab === name ? "c-tabs__content c-tabs__content--active" : "c-tabs__content"}>
                        {classInfo.sliderImages &&
                            <Slider {...sliderSettings} className="xs-pb-20 xs-pt-20 xs-pl-20 xs-pr-20">
                                {classInfo.sliderImages.map((image, index) => {
                                    if (image.image) {
                                        return <NonStretchedImage key={index} fluid={image.image.imageFile.childImageSharp.fluid} alt={image.altText} />;
                                    }
                                    return false;
                                })}
                            </Slider>
                        }

                        <BackgroundImage
                            Tag="div"
                            fluid={imageData.file.childImageSharp.fluid}
                            className="c-tabs__footer"
                            style={{
                                // Overrite defaults
                                backgroundPosition: 'bottom',
                            }}
                        >
                            <ul className="c-stats-list">
                                <li className="c-stats-list__item">
                                    <div className="c-stats-list__content">
                                        <span className="c-stats-list__title">{t("product.classes.durability-title")}</span>
                                        {durabilityStatus}
                                    </div>
                                </li>
                                <li className="c-stats-list__item">
                                    <div className="c-stats-list__content">
                                        <span className="c-stats-list__title">{t("product.classes.stability-title")}</span>
                                        {stabilityStatus}
                                    </div>
                                </li>
                                <li className="c-stats-list__item">
                                    <div className="c-stats-list__content">
                                        <span className="c-stats-list__title">{t("product.classes.stiffness-title")}</span>
                                        {stiffnessStatus}
                                    </div>
                                </li>
                                <li className="c-stats-list__item">
                                    <div className="c-stats-list__content">
                                        <span className="c-stats-list__title">{t("product.classes.weight-title")}</span>
                                        {weightStatus}
                                    </div>
                                </li>
                            </ul>
                        </BackgroundImage>
                    </div>
                );
            })}
        </div>
    );
};

export default withTranslation()(ProductClasses);
