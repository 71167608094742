import React from 'react';
import {graphql, useStaticQuery} from "gatsby";

const Faqs = ({language}) => {
    
    const data = useStaticQuery(graphql`

        fragment faqContent on WORDPRESS_GlobalContent {
            ACFFaqsBlockFields {
                faqs {
                    answer
                    question
                }
            }
        }

        query Faqs {
            wordpress {
                EN: globalContent(idType: SLUG, id: "faqs-en") {
                    ...faqContent
                }
                FR: globalContent(idType: SLUG, id: "faqs-fr") {
                    ...faqContent
                }
                ES: globalContent(idType: SLUG, id: "faqs-es") {
                    ...faqContent
                }
                ZH: globalContent(idType: SLUG, id: "faqs-zh") {
                    ...faqContent
                }
                DE: globalContent(idType: SLUG, id: "faqs-de") {
                    ...faqContent
                }
                PL: globalContent(idType: SLUG, id: "faqs-pl") {
                    ...faqContent
                }
            }
        }
    `);


    let faqs;
    // language is taken from the page's language code
    if(typeof data.wordpress[language] !== "undefined") {
        faqs = data.wordpress[language].ACFFaqsBlockFields.faqs;
    } else {
        faqs = data.wordpress['EN'].ACFFaqsBlockFields.faqs;
    } 
    
    return (
        <div className="c-faqs">
            {faqs.map((faq, index) => (
                <div key={index}>
                    <div className="c-faqs__question">Q: {faq.question}</div>
                    <div className="o-content" dangerouslySetInnerHTML={{ __html: faq.answer }}></div>
                </div>
            ))}
        </div>
    )
}

export default Faqs;
