import React from 'react';
import Img from "gatsby-image";

const ShoeOptions = ({ shoeOptions }) => (
    <div className="container-fluid">
        <div className="c-product-options">
            <div className="row">
                {shoeOptions && shoeOptions.map((option, index) => (
                    <div key={index} className="col-sm-6">
                        {option.image && 
                            <Img fluid={option.image.imageFile.childImageSharp.fluid} alt={option.image.altText}/>
                        }
                        <div className="c-product-options__title">{option.name}</div>
                    </div>
                ))}
            </div>
        </div>
    </div>
);

export default ShoeOptions;
