import React from 'react';
import {graphql, useStaticQuery} from "gatsby";
import {withTranslation} from "react-i18next";

const Testimonials = ({ t, language }) => {

    const data = useStaticQuery(graphql`

        fragment testimonialContent on WORDPRESS_GlobalContent {
            ACFTestimonialBlockFields {
                testimonials {
                    testimonial
                    testimonialTitle
                    company
                    author
                }
            }
        }

        query Testimonials {
            wordpress {
                EN: globalContent(idType: SLUG, id: "testimonials-en") {
                    ...testimonialContent
                }
                FR: globalContent(idType: SLUG, id: "testimonials-fr") {
                    ...testimonialContent
                }
                ES: globalContent(idType: SLUG, id: "testimonials-es") {
                    ...testimonialContent
                }
                ZH: globalContent(idType: SLUG, id: "testimonials-zh") {
                    ...testimonialContent
                }
                DE: globalContent(idType: SLUG, id: "testimonials-de") {
                    ...testimonialContent
                }
                PL: globalContent(idType: SLUG, id: "testimonials-pl") {
                    ...testimonialContent
                }
            }
        }
    `);

    let testimonials;
    // language is taken from the page's language code
    if(typeof data.wordpress[language] !== "undefined") {
        testimonials = data.wordpress[language].ACFTestimonialBlockFields.testimonials;
    } else {
        testimonials = data.wordpress['EN'].ACFTestimonialBlockFields.testimonials;
    } 

    return (
        <div className="container-fluid">
            <div className="c-testimonial-list">
                {testimonials && testimonials.map((testimonial, index) => (
                    <div key={index} className="row">
                        <div className="col-md-4">
                            <div className="c-testimonial-list__author">
                                <div>{t("product.spec-testimonials.title")}: {testimonial.company}</div>
                                <div>{testimonial.author}</div>
                            </div>
                        </div>
                        <div className="col-md-8">
                            <div className="c-testimonial-list__title">{testimonial.testimonialTitle}</div>
                            <div className="o-content" dangerouslySetInnerHTML={{ __html: testimonial.testimonial }}></div>
                        </div>
                    </div>
                ))}
            </div>
        </div>
    )
}

export default withTranslation()(Testimonials);
